@import url(https://fonts.googleapis.com/css?family=Allura);
.otp > input {
  width: 40px !important;
  height: 40px !important;
  border-radius: 4px;
  border: 1px solid #dcdce4;
}

.otp {
  width: 3rem;
}

.signpreview {
  font-family: 'Allura';
  position: absolute;
  font-size: 30px;
  font-weight: 400;
  top: 0px !important;
}
