.content_holder {
  margin-right: 40px;
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 8px;
    svg {
      color: black;
    }
  }
}

.home_icon {
  margin-top: -3px;
}

.sub_title {
  color: #667085;
}

.export_button {
  width: 167px;
  height: 40px;
}
