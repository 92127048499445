@primary-color: #4945ff;

.custom_layout_container {
  min-height: 100vh;

  :global(.ant-layout) {
    background: #f6f6f9;
  }

  .sider_container {
    overflow: auto;
    height: 83%;
    position: fixed;
    left: 20px;
    top: 100px;
    bottom: 0;

    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
    border-radius: 5px;
    :global(.ant-menu-vertical) {
      border: none;
    }
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #f3f3f4;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #fff;
          border-radius: 4px;
          margin-top: 14px;
          :global(.anticon) {
            color: #abace2;
          }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: #1e206a;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    .site_layout_header {
      height: 71px;
      position: fixed;
      z-index: 1;
      width: 100%;
      background: @primary-color;
      padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 30px;
      margin-top: 100px;
      margin-left: 285px;
    }

    .logo_header {
      height: 32px;
      margin: 0px;
    }
  }
}

.menu {
  background-color: @primary-color;
  display: flex;
  border-bottom: none;
  min-width: 50%;

  :global(.ant-menu-item a) {
    color: rgb(255 255 255 / 85%);
  }
  :global(.ant-menu-item a:active) {
    color: #4945ff;
  }

  :global(.ant-menu-item a:hover) {
    font-weight: 600;
    padding-right: 15px;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    background-color: white;
    border: 1px solid white;

    color: #4945ff;

    .icon {
      filter: brightness(0) saturate(100%) invert(25%) sepia(83%)
        saturate(5163%) hue-rotate(240deg) brightness(102%) contrast(101%);
    }
  }
}

.icon {
  padding-right: 5px;
  svg {
    margin-bottom: -3px;
  }
}

.active {
  font-weight: 600;
  padding-right: 15px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  background-color: white;
  border: 1px solid white;
  color: #4945ff;

  .icon {
    filter: brightness(0) saturate(100%) invert(25%) sepia(83%) saturate(5163%)
      hue-rotate(240deg) brightness(102%) contrast(101%);
  }
}

.custom_sidebar_container {
  border-radius: 4px;
  line-height: 30px !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;

  :global(.ant-menu-item-selected a) {
    color: #4945ff;
    font-weight: 800;
    border-radius: 4px;

    .side_icon {
      filter: brightness(0) saturate(100%) invert(25%) sepia(83%)
        saturate(5163%) hue-rotate(240deg) brightness(102%) contrast(101%);
    }
  }
}

:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background-color: #f3f0ff;
  border-radius: 5px;
}

.custom_layout_container1 {
  min-height: 100vh;

  :global(.ant-layout) {
    background: #f6f6f9;
  }
  .sider_container {
    overflow: auto;
    height: 83%;
    position: fixed;
    left: 20px;
    top: 100px;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
    border-radius: 5px;
    :global(.ant-menu-vertical) {
      border: none;
    }
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #f3f3f4;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #fff;
          border-radius: 4px;
          margin-top: 14px;
          :global(.anticon) {
            color: #abace2;
          }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: #1e206a;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    .site_layout_header {
      height: 71px;
      position: fixed;
      z-index: 1;
      width: 100%;
      background: @primary-color;
      padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 80px;
      margin-top: 115px;
      margin-left: 80px;
    }

    .logo_header {
      height: 32px;
      margin: 0px;
    }
  }
}
