.pdf_design {
  padding: 5%;
  margin: 2%;
  background-color: white;
  position: relative; /* Add relative positioning */
}

.pdf_design::before {
  opacity: 0.5;
  content: 'DRAFT'; /* Specify the watermark text */
  position: absolute; /* Position the pseudo-element */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%) rotate(-45deg); /* Center the pseudo-element */
  font-size: 140px; /* Set the font size of the watermark text */
  font-weight: bold; /* Set the font weight of the watermark text */
  color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Set the color and transparency of the watermark text */
  pointer-events: none; /* Ensure the watermark doesn't interfere with user interaction */
}

.logo {
  img {
    height: 70px;
    width: 70px;
  }
}

.title {
  p {
    font-size: 16px;
    text-decoration: underline;
    font-weight: 800;
  }
}

.underline {
  text-decoration: underline;
}
ol {
  counter-reset: list;
}

ol > li:before {
  content: counter(list, lower-alpha) ') ';
  counter-increment: list;
}
// ol > li:after {
//   content: counter(list, lower-alpha) ") ";
//   counter-increment: list;
// }

ol > li {
  list-style: none;
}

table {
  width: 100%;
}
.tatatable th,
.tatatable td {
  padding: 5px;
}
.tatatable1 th,
.tatatable1 td {
  padding: 5px;
}
.tatatable1 td {
  color: #000;
  font-weight: 500;
  width: 10%;
}
.tatatable1 {
  width: 100%;
  font-size: 14px;
  border: 1px solid #000;
}
.tatatable {
  width: 100%;
  font-size: 14px;
  border: 1px solid #000;
}
.tatatable td {
  color: #000;
  font-weight: 500;
  border: 1px solid #000;
}
.tatatable th {
  color: #000;
  background-color: #d9d9d9;
  border-bottom: 1px solid #000;
}
.tab th {
  background-color: #d9d9d9;
  border-right: 1px solid rgb(131, 131, 131);
}
.tab td {
  color: #000;
  border-bottom: 1px solid #000;
}
.tatatable1 th {
  color: #000;
  background-color: #d9d9d9;
  border-bottom: 1px solid #000;
}
.tab th {
  background-color: #d9d9d9;
  border-right: 1px solid rgb(131, 131, 131);
}
.tab td {
  color: #000;
  border-bottom: 1px solid #000;
}

.footer_logo {
  img {
    height: 100%;
    width: 100%;
  }
}
