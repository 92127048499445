.custom_form_item {
  :global(.ant-form-item-label) {
    padding: 0 0 4px;
    font-family: 'SFProText Bold';
  }
  :global(.ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  :global(.ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    display: none;
  }
 :global( .ant-input-number-input:placeholder-shown) {
    text-overflow: ellipsis;
    font-size: 11px;
}
:global(.ant-input:placeholder-shown ){
    text-overflow: ellipsis;
    padding-top: 10px;
    font-size: 11px;


   
}
:global(.ant-form-item-explain-error) {
    color: #ff4d4f;
    font-size: 11px;
}
}
