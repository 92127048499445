.collapse_container {
  width: 100%;
  height: auto;
  background: #ffffff;
  /* Neutral/150 */
  padding: 16px;
  border: 1px solid #eaeaef;
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 10px;
}
