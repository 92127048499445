.layout {
  background-color: #f5f7f9;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow-y: auto;
}

.init_env_header {
  position: fixed;
  padding: 15px;
  height: 80px;
  background-color: #ffffff;
  /* Card-shadow-hover */
  box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
  z-index: 999;
  top: 0px !important;
}

.x_button {
  content: 'x';
  height: 30px;
  width: 30px;
  border: 1px solid #dcdce4;
  border-radius: 4px;
}

.container {
  margin-top: 100px;
  height: 100vh;
  margin-bottom: 150px;
  margin-left: 40px;
  margin-right: 40px;
}

.badge {
  :global(.ant-badge-count) {
    background-color: black;
  }
}

:global(.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra) {
  margin: -2px 0 0 15px;
  color: #32324d;
  font-size: 20px;
  font-weight: 700;
}
:global(.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover) {
  border-color: #1cb697;
}

.upload > div > div {
  width: 100% !important;
  height: 280px !important;
}

// .upload_img > div > div{
//   width: 40% !important;
//   height: 200px !important;
// }

.upload_drag_text {
  margin-top: 97px !important;
  margin-left: -115px !important;
  font-size: 13px;
}

.upload_or_text {
  margin-top: 130px !important;
  margin-left: -95px !important;
  font-size: 13px;
}

.upload_button_text {
  margin-top: 209px !important;
  margin-left: -46px !important;
  font-size: 13px;
}

.upload_drag_img {
  width: 5% !important;
}

.vertical_line::before {
  border-left: 6px solid green;
  height: 100%;
}

.upload_img > div > div {
  width: 250px !important;
  height: 280px !important;
  margin-right: 20px;
}

.delete {
  border: 1px solid #dcdce4;
  padding: 4px;

  border-radius: 4px;
}

.upgrade {
  display: block;
}
:global(.ant-upload.ant-upload-drag) {
  height: 285px;
}

:global(.ant-upload.ant-upload-select-picture-card:hover) {
  border-color: #1cb697;
}

.delete_icon {
  border: 1px solid #dcdce4;
  height: fit-content;
  width: auto;
  padding: 12px;
  border-radius: 4px;
}

.span_add {
  color: #2c5cc5;
}

.text_area {
  width: 800px;
}
.container1 {
  width: 25%;
}

.step {
  padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v_stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 1px solid #a5a5ba;
  border-radius: 100%;
  width: 26px; /* +6 for border */
  height: 26px;
  display: inline-block;
  p {
    margin-left: 7px;
    margin-top: 1px;
  }
}

.step .line {
  top: 26px;
  left: 12px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 1px solid #1717c6;
}
.step:last-child .line {
  border-left: 1px solid rgb(6, 4, 4);
  z-index: -1; /* behind the circle to completely hide */
}
.approverIndex > div > div {
  position: absolute;
}

.approverdisIndex > div > div > div > input {
  cursor: not-allowed !important;
  pointer-events: none;
}
.approverdisIndex > div > div > div > div {
  display: none;
}
.approverdisIndex > div > div {
  background-color: #ebeff3 !important;
  border: 2px #ebeff3 !important;
}

.approverdisIndex > div > div > div > ul {
  display: none;
}

.circle {
  background-color: white;
  border: 1px solid #a5a5ba;
  border-radius: 100%;
  width: 26px; /* +6 for border */
  height: 26px;
  display: inline-block;
  margin-top: -3px;
  p {
    margin-left: 7px;
    margin-top: 1px;
  }
}

.line {
  top: 30px;
  left: 28px;
  height: 100%;
  position: absolute;
  border-left: 1px solid #a5a5ba;
}

.approver_line:last-child.line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}
.nodragndrop {
  pointer-events: none;
}

@media only screen and (max-width: 1200px) {
  :global(.ant-form-item-label > label) {
    font-size: 11px;
  }
}
@media only screen and (max-width: 1024px) {
  :global(.ant-form-item-label > label) {
    font-size: 10px;
  }
}

.remove_button {
  color: red;
  border: 1px solid red;
}

.remove_button:hover {
  background-color: red;
  color: white;
  transition: all 0.3s ease-in-out;
  border: red;
}

.upload {
  width: 30%;
}

:global(.ant-form input[type='file']) {
  display: none;
}

.custom_file_upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
}
.custom_file_upload:hover {
  display: inline-block;
  padding: 6px 12px;
  background-color: #4945ff;
  cursor: pointer;
  color: white;
  transition: all 0.4s ease-in-out;
}

.form_card {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 20px;
  border-left: 100px solid #302cc6;
}

.tabs_holder {
  width: auto;
  margin-top: -24px;
  margin-left: -23.8px;
  margin-right: -23.8px;
  height: 50px;
  background-color: #f5f7f9;
  border-top-right-radius: 20px;
  border-bottom: 1px solid #cfd7df;
}

.grey_holder {
  background: #f5f7f9;
  border-radius: 5px;
  padding-top: 15px;
}

.add_div {
  /* White */
  height: 50px;
  background: #ffffff;
  /* S/100 */
  color: #4945ff;
  border: 1px dashed #cfd7df;
  border-radius: 5px;
  svg {
    fill: #4945ff;
  }
}

.delete_holder {
  height: 28px;
  width: 28px;

  float: right;
  /* White */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  /* S/100 */

  border: 1px solid #cfd7df;
  border-radius: 4px;
}

.add_clause {
  color: #4945ff;

  svg {
    height: 20px;
    width: 20px;
    margin-bottom: -2px;
    fill: #4945ff;
  }
}

.dot {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-left: -60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #4945ff;
  box-shadow: 0px 0px 20px rgba(44, 92, 197, 0.25);
  margin-top: -25px;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height, or 100% */

  /* Primary/600 */

  color: #4945ff;
}
.dot_completed {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-left: -45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid green;
  box-shadow: 0px 0px 20px rgba(44, 92, 197, 0.25);
  margin-top: -25px;
}

.anti_bribery_viewer {
  width: 100%;
  height: auto;

  :global(.react-pdf__Page__canvas) {
    background-color: black;
    width: 180% !important;
    height: 100% !important;
  }
}

.anti_bribery {
  width: 55% !important;
  height: auto;
}

.header_text {
  color: #4945ff;
}
