.title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  /* identical to box height, or 107% */

  /* Neutral/900 */

  color: #212134;
}

.search_bar {
  width: 300px;
}

.divider {
  width: 0px;
  height: 15px;

  /* Neutral/300 */

  border: 1px solid #c0c0cf;
}

.custom_table {
  :global(.ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
    height: 0;
  }
  :global(.ant-table-thead > tr > th) {
    position: relative;
    font-family: 'SFProText Bold';
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    background: #fafafa;
    border-bottom: 10px solid #f6f6f9;

    line-height: 16px;
    color: #212134;
  }

  :global(.ant-table-tbody > tr > td) {
    border-bottom: 10px solid #f6f6f9;
  }
  :global(.ant-table table) {
    text-align: left;
  }
}

.use_button {
  margin: 0 auto;
  padding: 0;
  display: inline-block;

  text-align: center;
  width: 84px;
  height: 32px;
}
.down_button {
  margin: 0 auto;
  padding: 0;
  display: inline-block;

  text-align: center;
  width: 32px;
  height: 32px;
}

.table_icon {
  svg {
    width: 20px;
    height: 20px;
    fill: black;;
  }
}

.badge {
  :global(.ant-badge-count) {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;

    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background-color: #eaf5ff;
    border-radius: 2px;
    color: #4945ff;

    box-shadow: 0 0 0 1px #fff;
  }
}

.modal_button {
  width: 50%;
}
