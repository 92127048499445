.parent_div {
  /*  width: 100%;
          height: auto;
          background: #eaeaef;
          min-height: 100vh;
          padding: 50px; */
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    background-color: #dddddd;
    border: 1px solid black;
  }
  td,
  th {
    border: 1px solid BLACK;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: white;
  }
}

.document_viewer {
  display: table-row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.document_viewer_page > div {
  height: 10px !important;
  width: 100%;
  /* height: auto !important; */
}

:global(.react-pdf__Page__canvas) {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

:global(.annotationLayer .linkAnnotation > a) {
  background-color: red;
  position: fixed;

  margin: auto;
}
