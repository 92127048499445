html,
body {
  margin: 0;
  padding: 0;
}

.container {
  height: 100vh;
  position: relative;
  // background-image: url('../../images/backgroundNew.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Add any additional styling for the container if needed */
  margin-bottom: 0;
  overflow: hidden;
  line-height: 0;
}

.second_container {
  height: 100vh;
  position: relative;
  // background-image: url('../../images/Rectangle.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Add any additional styling for the container if needed */
  margin-bottom: 0;
  overflow: hidden;
  line-height: 0;
}

.logo_container {
  img {
    margin-top: 2%;
    margin-left: 5%;
    text-align: center;
    height: auto;
    max-width: 100%;
    width: 15%;
  }
}

@media (max-width: 768px) {
  .logo_container {
    text-align: center;
    margin: 20px 0;
    img {
      width: 50%;
    }
  }
}

.right_side {
  margin-top: -6%;
  width: 50%;
  height: 106%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/Group 11.svg');
  background-repeat: no-repeat;
  //background-size: cover;
  // background-position: center;
}

.centered_div {
  margin: 20%;
}

.left_side {
  width: 50%;
  height: 100%;
  float: left;
}

@media (max-width: 768px) {
  .left_side {
    display: none;
  }
  .right_side {
    width: 100%;
    background-image: none;
    background: linear-gradient(to top, #1c4e9d, #255fbb, #3c77d6);
  }
}

.responsive_image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.secondary_button {
  /* Add your button styles here */

  background-color: #eaeaea;
  color: #333;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.secondary_button:hover {
  transform: scale(1.05);
  background-color: #333;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.secondary_button:focus {
  outline: none;
}

.secondary_button:active {
  transform: scale(0.95);
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  font-size: 24px;
  margin-bottom: 40px;
  color: white;
  /* Add any additional styles for the heading */
}

.subtitle {
  /*  text-align: center; */
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1.5;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
  /* Add any additional styles for the subtitle */
}

.list {
  white-space: nowrap;
  color: #ffffff;
  font-size: 16px;
  list-style: none;
  line-height: 1.5;
}

.sub_heading {
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: 30px;
  color: white;
}

.list_container {
  margin-left: 20%;
  display: flex;
  flex-direction: column; /* Change flex-direction to "row" to make elements start from the same line */
  justify-content: center;
  align-items: flex-start;
}

.list_item {
  display: flex; /* Add this line */
}

.list_icon {
  margin-top: 1.2%;
  margin-right: 8px; /* Add this line to provide spacing between the icon and text */
}

.list_text {
  display: inline-block;
}

.list_icon span {
  display: inline-block;
}
.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .heading {
    text-align: center;
    font-size: 15px;
  }

  .list_container {
    margin-right: 0;
  }

  .list_text {
    margin-bottom: 5px;
  }

  .list {
    font-size: 13px;
  }

  .sub_heading {
    text-align: center;
  }

  .subtitle {
    text-align: center;
    font-size: 12px; /* Adjust the font size as needed */
    line-height: 1.5; /* Adjust the line height as needed */
  }

  .secondary_button {
    text-align: center;
  }

  .button_container {
    text-align: center;
  }
}
