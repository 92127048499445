.switcher_container {
  :global(.ant-typography) {
    color: #000000;
  }
  :global(.ant-switch-checked) {
    background: #4bab60;
  }
}

.switcher_container1 {
  :global(.ant-typography) {
    color: #000000;
  }

  :global(.ant-switch) {
    background: #ec3c3c;
  }
}
