@primary-color: #4945ff;

.user_menu_container {
  background: @primary-color;
  border: 1px solid #2a22ef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 10px;
  .user_menu_avatar {
    background: #f6f0a7;
    margin-right: 3px;
    color: #dcbe43;
  }
}
.icon_color {
  color: #ffffff;
}
